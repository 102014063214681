@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
  max-width: 720px;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 21px 0;

    &:nth-child(even) {
      margin: 0 0 21px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 21px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: #F6F9F4;

  /* @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  } */

  border: 2px dashed var(--customGrayBorder);
  border-radius: var(--borderRadiusWrapper);

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  margin: 0 0 2px;
}

.imageTypes {
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--customGray);

  margin: 0;
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  font-size: 15px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButtonHolder {
  margin-top: 44px;
  margin-bottom: 24px;
  
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    margin-bottom: 95px;
  }
}

.submitButton {
  flex-shrink: 0;
  margin-right: 20px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 320px;
    /* margin-top: 100px; */
  }
}

.seePreviewButton {
  @apply --marketplaceButtonStyles;
  flex-shrink: 0;
  margin-top: 24px;
  padding: 0;

  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
    background: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 320px;
    margin-top: 0;
  }
}
