@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {

}

.wrapper {

}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.servicesHolder {
  margin: 60px 0 30px;
  
  @media (--viewportSmall) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.services {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 14px;
}

.servicesTwoColums {
  @media (--viewportSmall) {
    width: calc(50% - 20px);
  }
}

.servicesTitle {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100% - 35px);

  @media (--viewportSmall) {
    flex-direction: row;
    width: calc(100% - 78px);
  }
}

.servicesTitleHolder {
  line-height: 40px;
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  word-break: break-word;
  margin-right: 20px;

  @media (--viewportSmall) {
    margin-right: 0;
  }

  & svg {
    width: 45px;
    margin: 0 10px 0 0;
  }
}

.servicesPriceHolder {
  font-size: 24px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
}

.servicesAction {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  align-self: center;
  white-space: nowrap;

  & label {
    margin: 0;
  }
}

.spanDelete {

}

.spanClose {
  margin-left: 5px;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  color: var(--failColor);
}

.spanAdd {
  @apply --marketplaceButtonStyles;
  padding: 0 30px;
  width: 100%;
  
  @media (--viewportSmall) {
    max-width: 170px;
  }
}

.blockAddServiceIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blockAddServiceIconDisables {
  cursor: not-allowed;

  & .spanAdd {
    background-color: var(--matterColorAnti);
    color: var(--matterColorLight);
    box-shadow: none;
    pointer-events: none;
  }
}

.iconBlockAdd {
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.wrapperForm {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.buttonAddService {
  /* margin-left: 20px; */
  cursor: pointer;
  width: max-content;
  font-size: 16px;
  line-height: 21px;
  font-weight: var(--fontWeightRegular);
}

.wrapperInputBlock {
  /* display: flex;
  align-items: center; */
  margin-bottom: 20px;
}

.wrapperInputPrice {
  margin-top: 20px;
  
  @media (--viewportMLarge) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 0;
  }
}

.inputComponentText {
  margin-right: 45px;
  width: 150px;
}

.inputComponentPrice {
  width: 100px;
}

.inputNewService {
  width: 100%;
  max-width: 754px;
  border-radius: var(--borderRadiusWrapper);
  min-height: 60px;
  height: 60px;
}

.error {
  color: var(--failColor);
}

.optionalLabel {
  margin-left: 5px;
}

.priceWrapper {
  
  @media (--viewportSmall) {
    display: flex;
  }
}

.priceHolder {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;

  @media (--viewportSmall) {
    margin: 0 20px 20px 0;
  }
    
  @media (--viewportMLarge) {
    margin: 20px 40px 0 0;
  }
  

  & label {
    position: relative;
    padding: 0;
    opacity: 0.4;
    pointer-events: none;
    display: block;
    width: 100%;

    @media (--viewportMLarge) {
      display: inline;
      width: auto;
    }

    & span {
      line-height: 24px;
    }

    &:before {
      content: "(PLN)";
      display: inline-block;
      position: absolute;
      bottom: 23px;
      left: 20px;
    }
  }

  & input {
    margin-top: 3px;
    width: 100%;

    padding: 17px 20px 17px 64px;
    
    @media (--viewportSmall) {
      max-width: 170px;
    }
  }

  & svg {
    margin: 20px 12px 0 0;
    cursor: pointer;
  }

  & svg g {
    display: none;
  }
}

.priceHolderActive {
  

  & label {
    padding: 0;
    opacity: 1;
    pointer-events: all;
  }

  & input {
    margin-top: 3px;
  }

  & svg g {
    display: block;
  }
}
