.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 320px;
    /* margin-top: 100px; */
  }
}

.itemsRow {

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 9px;
  }

  & label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #252525;
  }
}

.itemsCol {
  width: 100%;
  margin: 0 0 30px;

  @media (--viewportMedium) {
    width: calc(50% - 10px);
    margin: 0;
  }
}

.item {
  width: 100%;
}

.itemDesc {
  width: 100%;
  min-height: 72px;
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252525;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.itemsRowDesc {
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
  }
}

.itemWithLabel {
  position: relative;
  width: 100%;
  margin: 0 0 30px;

  @media (--viewportMedium) {
    width: calc(50% - 10px);
    margin: 0;
  }

  & .daysLabel {
    position: absolute;
    left: auto;
    right: 0;
    top: 48px;


    @media (--viewportMedium) {
      left: 162px;
      right: auto;
      top: 72px;
    }
  }
}

.itemSmall {

  & input {
    width: 100%;
    max-width: calc(100% - 50px);

    @media (--viewportMedium) {
      max-width: 152px;
    }
  }
}

.setThePriceLabel {
  color: #2F4E1E;
  text-decoration: underline;
  cursor: pointer;
}

.greenText {
  color: var(--marketplaceColor);
}

.grayText {
  color: var(--customGray);
}

.pricingTitle {
  margin: 10px 0;
  color: var(--matterColorDark);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
}

.discountTitle {
  margin: 0 0 6px;
  color: var(--matterColorDark);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
}

.settingsPricingTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #252525;
  margin: 0 0 12px;
}

.pricingSwither {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.pricingSwitherItem {
  width: 50%;

  & label {
    width: 100%;
    display: block;
    padding: 12px 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--customGrayBorder);
  }

  & label > span:first-of-type {
    display: none;
  }

  & label > span:last-of-type {
    font-size: 18px;
    font-weight: var(--fontWeightMedium);
  }

  & input:checked + label {
    background: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
  }

  & input:checked + label span  {
    color: var(--matterColorLight) !important;
  }
}

.pricingSwitherItemBlocked {
  cursor: not-allowed;

  & label {
    pointer-events: none;
  }
}

.pricingSwitherItem:first-of-type {

  & label {
    border-radius: 18px 0 0 18px;
  }
}

.pricingSwitherItem:last-of-type {

  & label {
    border-radius: 0 18px 18px 0;
    border-left: none;
  }
}

.pricingModels {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 20px;
  }
}

.pricingModelsItem {
  width: 100%;
  padding: 20px 20px 17px;
  border-radius: var(--borderRadiusWrapper);
  border: 1px solid var(--customGrayBorder);
  margin-bottom: 20px;
  font-weight: var(--fontWeightRegular);

  order: 2;

  @media (--viewportLarge) {
    width: 50%;
    margin-bottom: 0;
    order: unset;
  }

  & .pricingTitle {
    margin: 0 0 12px;
  }

  & input {
    color: transparent;
    pointer-events: none;
    border-color: var(--customGrayBorder);

    &::placeholder {
      color: transparent;
    }
  }

  & label {
    pointer-events: none;
    font-size: 14px;
    padding: 0 0 3px;
    line-height: 24px;
  }

  & [class*="ValidationError_root"] {
    display: none;
  }
}

.pricingModelsItemActive {
  background: var(--customLightGreen);
  order: 1;

  @media (--viewportLarge) {
    order: unset;
  }

  & input {
    color: var(--matterColor);
    pointer-events: all;
    /* border-color: initial; */

    &::placeholder {
      color: var(--customGray);
    }
  }

  & label {
    pointer-events: all;
  }

  & input[class*="FieldCurrencyInput_inputSuccess"] {
    border-color: var(--matterColor);
  }

  & input[class*="FieldCurrencyInput_inputError"] {
    border-color: var(--failColor);
  }

  & [class*="ValidationError_root"] {
    display: block;
  }
}

.pricingModelsItemBlocked {
  cursor: not-allowed;

  & > * {
    pointer-events: none;
  }
}

.pricingModelsSeparator {
  display: none;
  width: 1px;
  height: 100%;
  margin: 0 20px;
  background: var(--marketplaceColorLight);
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: block;
  }
}

.weekdaysHolder {
  margin: 0 0 20px;

  @media (--viewportSmall) {
    display: flex;
  }

  @media (--viewportLarge) {
    justify-content: space-between;
  }
}

.weekdaysField {
  margin: 0 0 20px 0;

  @media (--viewportSmall) {
    margin: 0 20px 0 0;
    width: 152px;
  }

  @media (--viewportLarge) {
    margin: 0 0 0 0;
  }

  & input {
    background: var(--matterColorLight);
    border-color: var(--customGrayBorder);
  }

  /* & input[class*="FieldCurrencyInput_inputSuccess"] {
    border-color: var(--matterColor);
  }

  & input[class*="FieldCurrencyInput_inputError"] {
    border-color: var(--failColor);
  } */
}

.pricingModels {

  & input[class*="FieldCurrencyInput_input"] {
    border-color: var(--customGrayBorder);
  }

  & input[class*="FieldCurrencyInput_input"]:focus,
  & input[class*="FieldCurrencyInput_input"]:hover {
    border-color: var(--matterColor);
  }
}

.discountItem {
  position: relative;
  margin: 0 0 13px;
  font-size: 14px;

  &:last-of-type {
    margin: 0;
  }

  @media (--viewportSmall) {
    display: flex;
    align-items: center;
  }
}

.discountItemField {

  & input {
    width: calc(100% - 75px);
    height: 36px;
    min-height: 36px;
    background: var(--matterColorLight);
    padding: 0 12px;
    margin: 0 0 3px;

    @media (--viewportSmall) {
      width: 50px;
      margin: 0;
    }
  }
}

.discountItemLabel {
  font-weight: var(--fontWeightSemiBold);
  margin-right: 5px;
  flex-shrink: 0;
  padding: 0;
}

.discountFromText {
  flex-shrink: 0;
  position: absolute;
  bottom: 46px;
  right: 0;

  @media (--viewportSmall) {
    position: static;
    margin: 0 5px 0 3px;
  }
}

.lastText {
  position: absolute;
  bottom: 6px;
  right: 0;

  @media (--viewportSmall) {
    margin: 0 0 0 3px;
    position: static;
  }
}

.priceOptionsDesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252525;
  margin: 0 0 25px;
}

.bold {
  font-weight: 700;
}

.openPriceSettings {
  margin-bottom: 40px;
}

.closePriceSettings {
  margin-bottom: 20px;
}

.isObligatory {
  color: #A8A8A8;
  font-weight: 400;
}

.pricingSubTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252525;
  margin: 0 0 5px;
}

.perUnitPricingSubTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #252525;
  margin: 0 0 13px;
}

.perUnitDiscountTitle {
  margin: 0 0 12px;
  color: var(--matterColorDark);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
}
