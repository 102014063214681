@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';


.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  @apply --marketplaceH1CustomFontStyles;
  margin: 0 0 24px;
}

