@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  color: var(--matterColor);
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 154px;
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  padding: 10px 10px 15px;
  border: 1px solid var(--matterColorNegative);
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.priceHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  /* Layout */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin: 0 4px 0 0;
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  font-size: 10px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 2px;
}

.amenities {
  & svg {
    margin-right: 12px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 6px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.description {
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
}

.ratingHolder {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0 0 7px;
}

.ratingTotal {
  margin-left: 6px;
  font-size: 12px;
  color: var(--customBlue);
  text-decoration: underline;
  font-weight: var(--fontWeightRegular);
}

.ratingStar {
  width: 13px;
}
