@import '../../styles/propertySets.css';

.root {
  /* position: fixed; */
  height: auto;
  z-index: 1;

  width: 100%;

  @media (--viewportMLarge) {
    width: auto;
    /* margin-right: 20px; */
  }

  @media (--viewportLLarge) {
    margin-right: 0;
  }
}

.menuLabel {
  @apply --marketplaceSearchFilterButton;
  @apply --marketplaceSearchFilterLabelFontStyles;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 10px;
  min-width: 120px;
  margin: 0 0 14px 0;
  width: 100%;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMLarge) {
    /* width: auto; */
    margin: 0 10px;
  }

  @media (--viewportLLarge) {
    min-width: 200px;
  }

  &:before {
    display: none;
  }

  &:hover,
  &:focus {
    & svg path {
      stroke: var(--matterColorLight);
    }
  }
}

.menuLabelSelected {
  @apply --marketplaceSearchFilterLabelFontStyles;
  /* @apply --marketplaceSearchFilterButtonSelected; */
  @apply --marketplaceSearchFilterButton;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 10px;
  min-width: 120px;
  margin: 0  0 14px 0;
  width: 100%;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightRegular);

  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);


  @media (--viewportMLarge) {
    /* width: auto; */
    margin: 0 10px;
  }

  @media (--viewportLLarge) {
    min-width: 200px;
  }

  &:before {
    display: none;
  }

  & svg path {
    stroke: var(--matterColorLight);
  }

  &:hover,
  &:focus {
    & svg path {
      stroke: var(--matterColorLight);
    }
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: 4px;

  left: 0 !important;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.icon {
  fill: none;
}
