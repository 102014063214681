
.button {
  color: var(--customBlue);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 2px;
}

.buttonLess {
  display: block;
  text-align: center;
  margin: 5px 0;
}

.dots {

}
