@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {

}

/* .lastIconActive {

  & .servicesItem:last-child svg g {
    display: inline;
  }
} */

.services {
  display: flex;
  flex-wrap: wrap;
}

.servicesItem {
  width: 100%;
  margin-bottom: 6px;

  @media (--viewportSmall) {
    width: calc(100%/2);
  }

  @media (--viewportMedium) {
    width: calc(100%/3);
  }

  & label span {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}

.servicesIcon {
  width: 40px;
  margin-right: 10px;
  flex-shrink: 0;
}
