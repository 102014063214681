@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceButtonStyles;
  width: 180px;
  min-height: 36px;
  padding: 9px 0;

  font-size: 14px;
  line-height: 17px;
  font-weight: var(--fontWeightMedium);
}

.simpleIcon {
  max-width: 54px;
  background: none;
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);
  padding: 16px 0;
  flex-shrink: 0;

  &:hover {
    background: none;
    box-shadow: none;
  }
}

.icon {
  /* width: 23px;
  height: 20px; */

  & path {
    stroke: var(--marketplaceColor);
  }
}

.iconActive {
  
  & path {
    fill: var(--marketplaceColor);
  }
}
