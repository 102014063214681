.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  & label {
    font-size: 14px;
    line-height: 24px;
    padding: 0 0 3px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 60px;
  line-height: unset;
  /* padding-left: 0; */
  margin: 0;
  /* border: 1px solid var(--attentionColor); */

  &:hover,
  &:focus {
    /* border-color: var(--matterColorDark); */
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 53px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

}

.validLocation {
  border-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 30px;
}

.mapHolder {
  height: 315px;
  margin: 0 0 20px;
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 315px;
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 756px;
  max-height: 756px;
  border-radius: var(--borderRadiusWrapper);
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  overflow: hidden;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 320px;
    /* margin-top: 100px; */
  }
}
