@import '../../styles/propertySets.css';

.root {
  display: inline-block;
  height: auto;

  width: 100%;

  @media (--viewportMLarge) {
    width: auto;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  @apply --marketplaceSearchFilterButton;
  @apply --marketplaceSearchFilterLabelFontStyles;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  min-width: 150px;
  margin: 0;
  width: 100%;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMLarge) {
    width: auto;
    margin: 0 10px;
  }

  @media (--viewportLLarge) {
    min-width: 196px;
  }

  &:before {
    display: none;
  }

  &:hover,
  &:focus {
    & svg path {
      stroke: var(--matterColorLight);
    }
  }
}

.menuLabelSelected {
  @apply --marketplaceSearchFilterLabelFontStyles;
  /* @apply --marketplaceSearchFilterButtonSelected; */
  @apply --marketplaceSearchFilterButton;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  min-width: 150px;
  margin: 0;
  width: 100%;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightRegular);

  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  @media (--viewportMLarge) {
    width: auto;
    margin: 0 10px;
  }

  @media (--viewportLLarge) {
    min-width: 196px;
  }

  &:before {
    display: none;
  }

  & svg path {
    stroke: var(--matterColorLight);
  }

  &:hover,
  &:focus {
    & svg path {
      stroke: var(--matterColorLight);
    }
  }
}

/* .menuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.menuLabelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
} */

.menuContent {
  margin-top: 7px;
  padding: 13px 30px;
  min-width: 220px;
  border-radius: 4px;

  left: 0 !important;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 220px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 220px;
  margin: 0;
  padding: 16px 0 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.icon {
  fill: none;
}
 