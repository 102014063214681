@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  fill: none;
}

.rootActive {
  fill: #fff;
}
