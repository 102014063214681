@import '../../styles/propertySets.css';

.root {
  position: relative;
  /* padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative); */
}

.menuLabel {
  @apply --marketplaceSearchFilterButton;
  @apply --marketplaceSearchFilterLabelFontStyles;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-width: 120px;
  margin: 0 10px;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportLLarge) {
    min-width: 200px;
  }

  &:before {
    display: none;
  }

  &:hover,
  &:focus {
    & svg path {
      stroke: var(--matterColorLight);
    }
  }
}

.menuLabelSelected {
  @apply --marketplaceSearchFilterLabelFontStyles;
  @apply --marketplaceSearchFilterButtonSelected;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-width: 120px;
  margin: 0 10px;

  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportLLarge) {
    min-width: 200px;
  }

  &:before {
    display: none;
  }

  & svg path {
    stroke: var(--matterColorLight);
  }

  &:hover,
  &:focus {
    & svg path {
      stroke: var(--marketplaceColor);
    }
  }
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

/* .formWrapper {
  padding-left: 20px;
} */

.clearButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  margin: 0;

  /* Layout */
  display: inline;
  /* float: right; */
  /* margin-top: 6px; */
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.labelWithIcon {
  width: 100%;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 24px;
  background: var(--matterColorLight);
  color: var(--matterColor);
  border: 1px solid var(--customGrayBorder);

  font-size: 14px;
  line-height: 1;
  font-weight: var(--fontWeightRegular);
  
  &:hover,
  &:focus {
    background: var(--matterColorLight);
    color: var(--matterColor);
    border: 1px solid var(--customGrayBorder);
    box-shadow: none;
  }
}

.labelSelected.labelWithIcon {
  background: var(--marketplaceColor);
  color: var(--matterColorLight);

  & svg path{
    stroke: var(--matterColorLight);
  }
}