.root {
}

.input {
  border-color: var(--customGrayBorder);
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);

  &:hover,
  &:focus {
    border-color: var(--failColor);
  }
}

.textarea {
}
