@import '../../styles/propertySets.css';

.root {
  padding: 24px 20px;
  background: var(--marketplaceColorLight);
  border-radius: 38px;
}

.rootTopbar {
  padding: 13px 10px;
  border-radius: 22px;
  max-width: 883px;
  margin: 30px auto 0;
  
  @media (--viewportLLarge) {
    border-radius: 42px;
    margin: 0 auto;
  }

  & > form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (--viewportLLarge) {
      flex-wrap: nowrap;
    }
  }

  & > form > div {
    width: 100%;

    @media (--viewportLLarge) {
      width: auto;
    }
  }

  & .advancedSearchLocation + div {
    position: absolute;
    bottom: -15px;
  }

  & .locationAutocompleteInput {
    width: 100%;
    padding: 8px 15px 8px 30px;
    min-height: 36px;
    font-size: 12px;
    line-height: 14px;
    background-size: 10px;
    background-position: 15px center;
    
    @media (--viewportLLarge) {
      margin-right: 10px;
      width: 240px;
    }
  }

  & .searchFooter {
    width: 100%;
    display: block;
    margin-top: 10px;
    
    @media (--viewportLLarge) {
      margin-top: 0;
      display: flex;
    }
  }

  & .categoriesItemsHolder {
    margin-top: 12px;
  }

  & .categoriesItems {
    padding: 20px 20px;
    justify-content: flex-start;
    align-items: center;

    & svg {
      width: 35px;
      margin-right: 12px;
    }
  }

  & .guestsAndDates {
    display: block;
    min-width: auto;
    margin-top: 10px;
    margin-bottom: 0;
    
    @media (--viewportLLarge) {
      justify-content: flex-start;
      display: flex;
      margin-top: 0;
    }
  }

  & .dates {
    width: 100%;
    max-width: 100%;
    margin: 0 10px 0 0;
    
    @media (--viewportLLarge) {
      max-width: 200px;
    }
  }

  & .dates [class*="FilterPopup_labelWithIcon"] {
    min-height: 36px;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    
    @media (--viewportLLarge) {
      width: 200px;
      margin-bottom: 0;
    }

    & svg {
      width: 13px;
      height: 13px;
    }
  }

  & .guests {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px 0;

    @media (--viewportLLarge) {
      max-width: 120px;
      margin: 0 10px 0 0;
    }
  }

  & .guestsMenuContent {
    left: 0;
    right: auto;
  }

  & .guestsMenuLabel {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 14px;
    min-height: 36px;
    width: 100%;
    
    @media (--viewportLLarge) {
      width: 120px;
    }

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  & .submitButton {
    max-width: 100%;
    min-height: 36px;
    transform: rotateY(180deg);
    flex-shrink: 0;
    
    @media (--viewportLLarge) {
      max-width: 62px;
    }

    & svg {
      stroke: var(--matterColorLight);
    }
  }
}

.advancedSearchLocation {

}

.advancedSearchLocationCurrent {

  & input::placeholder {
    color: var(--matterColorDark);
  }
}

.locationAutocompleteInput {
  background: var(--matterColorLight);
  padding: 14px 20px 14px 42px;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='19' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 16.4167L11.625 12.2917C12.4408 11.4759 12.9963 10.4365 13.2213 9.30491C13.4463 8.17337 13.3308 7.00051 12.8892 5.93465C12.4477 4.86878 11.7 3.95777 10.7408 3.31682C9.78149 2.67587 8.6537 2.33377 7.5 2.33377C6.3463 2.33377 5.21851 2.67587 4.25924 3.31682C3.29997 3.95777 2.55229 4.86878 2.11076 5.93465C1.66923 7.00051 1.55368 8.17337 1.77871 9.30491C2.00374 10.4365 2.55926 11.4759 3.375 12.2917L7.5 16.4167ZM7.5 18.7733L2.19667 13.47C1.14779 12.4211 0.433489 11.0847 0.144107 9.62989C-0.145275 8.17504 0.00325571 6.66704 0.570916 5.2966C1.13858 3.92616 2.09987 2.75483 3.33324 1.93072C4.5666 1.10661 6.01665 0.666748 7.5 0.666748C8.98336 0.666748 10.4334 1.10661 11.6668 1.93072C12.9001 2.75483 13.8614 3.92616 14.4291 5.2966C14.9968 6.66704 15.1453 8.17504 14.8559 9.62989C14.5665 11.0847 13.8522 12.4211 12.8033 13.47L7.5 18.7733ZM7.5 9.83335C7.94203 9.83335 8.36595 9.65775 8.67851 9.34519C8.99108 9.03263 9.16667 8.60871 9.16667 8.16668C9.16667 7.72466 8.99108 7.30073 8.67851 6.98817C8.36595 6.67561 7.94203 6.50002 7.5 6.50002C7.05798 6.50002 6.63405 6.67561 6.32149 6.98817C6.00893 7.30073 5.83334 7.72466 5.83334 8.16668C5.83334 8.60871 6.00893 9.03263 6.32149 9.34519C6.63405 9.65775 7.05798 9.83335 7.5 9.83335ZM7.5 11.5C6.61595 11.5 5.7681 11.1488 5.14298 10.5237C4.51786 9.89858 4.16667 9.05074 4.16667 8.16668C4.16667 7.28263 4.51786 6.43478 5.14298 5.80966C5.7681 5.18454 6.61595 4.83335 7.5 4.83335C8.38406 4.83335 9.2319 5.18454 9.85703 5.80966C10.4821 6.43478 10.8333 7.28263 10.8333 8.16668C10.8333 9.05074 10.4821 9.89858 9.85703 10.5237C9.2319 11.1488 8.38406 11.5 7.5 11.5Z' fill='%232F4E1E'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 20px center;
  min-height: 54px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.searchFooter {
  margin: 20px 0 0;
  
  @media (--viewportMLarge) {
    display: flex;
    align-items: center;
  }
}

.categories {
  display: flex;
  align-items: center;
}

.categoriesLabel {
  padding: 0;
  margin: 0 20px 0 0;
  display: none;

  @media (--viewportMLarge) {
    display: inline-block;
  }
}

.categoriesLabelTopbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 9px 10px;
  margin: 0 10px 0 0;
  background: var(--matterColorLight);
  border-radius: var(--borderRadiusWrapper);
  border: 1px solid var(--customGrayBorder);
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;

  @media (--viewportLLarge) {
    width: 200px;
  }
  
  &:hover,
  &:focus {
    background: var(--matterColorLight);
  }

  & > svg{
    fill: none;
  }
}

.categoriesLabelTopbarOpen {

}

.categoriesLabelTopbarActive {

  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  
  &:hover,
  &:focus {
    background: var(--matterColorLight);
    color: var(--marketplaceColor);

    & > svg path {
      stroke: var(--marketplaceColor);
    }
  }

  & > svg path {
    stroke: var(--matterColorLight);
  }
}

.categoriesItems {
  width: 100%;

  @media (--viewportSmall) {
    display: flex;
    justify-content: space-between;
  }

  @media (--viewportMLarge) {
    width: auto;
  }
}

.categoriesItem {
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;

  @media (--viewportSmall) {
    width: calc((100%/3) - 10px);
    margin-bottom: 0;

  }

  @media (--viewportMLarge) {
    width: auto;
  }
}

.guestsAndDates {
  width: 100%;
  margin: 0 0 20px 0;
  
  @media (--viewportSmall) {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    min-width: 335px;
  }
  
  @media (--viewportMLarge) {
    width: auto;
    margin: 0;
  }

  @media (--viewportLLarge) {
    min-width: 484px;
  }

  
}

.dates {
  width: 100%;
  margin-bottom: 20px;
  
  @media (--viewportSmall) {
    width: calc(50% - 10px);
    margin-bottom: 0;
  }
  
  @media (--viewportMLarge) {
    width: 100%;
    margin-right: 15px;
    max-width: 226px;
  }

  & > div,
  & > div > div {
    display: block;
  }

  & [class*="FilterPopup_popup"] {
    min-width: 250px;
    
    @media (--viewportLLarge) {
      left: -50px !important;
    }
  }

  & [class*="FilterPopup_labelWithIcon"] {
    padding-right: 20px;

    &:before {
      display: none;
    }
  }
}

.guests {
  width: 100%;
  position: relative;

  @media (--viewportSmall) {
    width: calc(50% - 10px);
  }
  
  @media (--viewportMLarge) {
    width: 100%;
    margin-right: 19px;
    max-width: 222px;
  }
}

.guestsOpen .guestsMenuContent {
  display: block;
}

.guestsClosed .guestsMenuContent {
  display: none;
}

.guests span::selection {
  background: none;
  color: var(--matterColor);
}
.guests span::-moz-selection {
  background: none;
  color: var(--matterColor);
}

.guests .applyBtn span::selection {
  background: none;
  color: var(--matterColorLight);
}
.guests .applyBtn span::-moz-selection {
  background: none;
  color: var(--matterColorLight);
}


.guestsMenuLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14.5px 20px;
  border-radius: 24px;
  background: var(--matterColorLight);
  font-size: 14px;
  line-height: 1;
  border-bottom: 1px solid var(--customGrayBorder);
  cursor: pointer;

  &:hover {
    background: var(--marketplaceColor);
    color: var(--matterColorLight);

    & path {
      stroke: var(--matterColorLight);
    }
  }
}

.guestsMenuLabelActive {
  background: var(--marketplaceColor);
  color: var(--matterColorLight);

  & path {
    stroke: var(--matterColorLight);
  }

  &:hover {
    background: var(--marketplaceColor);
    color: var(--matterColorLight);

    & path {
      stroke: var(--matterColorLight);
    }
  }
}

.guestsMenuIsOpen {
  background: var(--marketplaceColor);
  color: var(--matterColorLight);

  & path {
    stroke: var(--matterColorLight);
  }
}

.guestsMenuContent {
  position: absolute;
  width: 250px;
  padding: 20px 20px 16px;
  background: var(--matterColorLight);
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  margin-top: 6px;
  right: 0;
  left: auto;
  z-index: 1;

  @media (--viewportSmall) {
    width: 278px;
  }

  @media (--viewportMedium) {
    left: 0;
    right: auto;
  }
}

.guestsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--marketplaceColorLight);
}

.guestsItemCounter {
  display: flex;
  align-items: center;
}

.guestsItemCounterValue {
  display: inline-block;
  margin: 0 14px;
}

.guestsBtnsHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guestsItemCounterBlocked {
  opacity: 0;
  pointer-events: none
}

.applyBtn {
  @apply --marketplaceButtonStyles;
  max-width: 124px;
  min-height: 40px;
  padding: 0;
  cursor: pointer;
}

.clearBtn {
  cursor: pointer;
  text-decoration: underline;
  color: var(--marketplaceColor);
  margin-right: 25px;
}

.submitButton {
  min-height: 54px;
  
  @media (--viewportMLarge) {
    max-width: 222px;
    flex-shrink: 0;
  }
} 
