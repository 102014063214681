.root {
  fill: none;
}

.rootRent {
  
}

.fillColor {
  fill: var(--successColor);
}
