@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: block;
  margin-left: 0px;
  max-width: 120px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.languageButton {
  text-transform: uppercase;
  padding: 0;
  margin: 15px 0 20px;

  @media (--viewportMedium) {
    padding: 0 25px 0 8px;
    margin: 0;
  }

  & .languageOption {
    padding: 0;

    @media (--viewportMedium) {
      padding: 16px 0 10px;
    }
  }
}

.languagesOptions {
  max-width: 95px;
  top: 42px;
  left: 0;

  @media (--viewportMedium) {
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  & > div {
    position: absolute;
    right: 50%;
  }
}

.languageOption {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  font-weight: var(--fontWeightSemiBold);

  &:hover,
  &:focus {
    color: var(--marketplaceColor);
  }
}

.flagImg {
  width: 24px;
  height: auto;
  flex-shrink: 0;
  margin-right: 10px;
}

.arrowDown {
  display: inline-block;
  margin-left: 7px;
  width: 8px;
  height: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 4L7.4641 0.25H0.535898L4 4Z' fill='%23252525'/%3E%3C/svg%3E%0A");
}
