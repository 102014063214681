@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  /* padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  } */
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  /* height: 24px; */
  height: 40px;
  line-height: 40px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  line-height: 40px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightRegular);
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    );
  }
}

.listLabel {
  margin: 0 0 18px;
}

.seeMore {
  color: var(--customBlue);
  text-decoration: underline;
  cursor: pointer;
}
