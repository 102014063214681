@import '../../styles/propertySets.css';

.root {
  margin: 26px 0 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.reviewItem {
  margin-bottom: 37px;
  width: 100%;
    
  @media (--viewportMedium) {
    width: 50%;
    max-width: 497px;
    padding-right: 20px;
  }
}

.review {
  /* display: flex;
  flex-direction: row; */
  /* flex-basis: 564px; */
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 11px;
  width: 56px;
  height: 56px;
}

.reviewContent {
  margin: 0;
  white-space: normal;
  line-height: 24px;
}

.reviewInfo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & a span {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;
  }
}

/* .reviewInfo {
  @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
} */

.reviewAuthorName {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
}

.reviewDate {
  font-size: 14px;
  line-height: 24px;
  color: var(--matterColorAnti);
  margin-top: -6px;
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.showAllReviews {
  width: 100%;
  text-align: center;
  color: var(--customBlue);
  text-decoration: underline;
  cursor: pointer;
}
