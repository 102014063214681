.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  /* @media (--viewportMedium) {
    margin-bottom: 32px;
  } */
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 320px;
    /* margin-top: 100px; */
  }
}

.radioButtonRow {
  border: 1px solid var(--customGrayBorder);
  border-radius: 18px;
  overflow: hidden;
  margin: 0 0 30px;
  
  @media (--viewportSmall) {
    display: flex;
    align-items: center;
  }
}

.radioButtonRowBlocked {
  cursor: not-allowed;
  /* opacity: 0.75; */

  & .radioButtonItem {
    pointer-events: none;
  }
}

.radioButtonItem {
  display: flex;
  
  @media (--viewportSmall) {
    width: calc(100%/3);
    display: inline-block;
  }
  
  &:nth-child(2) {
    border-top: 1px solid var(--customGrayBorder);
    border-bottom: 1px solid var(--customGrayBorder);
    
    @media (--viewportSmall) {
      border-left: 1px solid var(--customGrayBorder);
      border-right: 1px solid var(--customGrayBorder);

      border-top: none;
      border-bottom: none;
    }
  }

  & label {
    width: 100%;
    display: block;
    padding: 14px 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
  }

  & label > span:first-of-type {
    display: none;
  }

  & input:checked + label {
    background: var(--marketplaceColor);
  }
  
  & input:checked + label span  {
    color: var(--matterColorLight) !important;
  }
}

.features {

  & legend {
    font-size: 16px;
    font-weight: var(--fontWeightBold);
  }
}
