@import '../../styles/propertySets.css';



.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 48px);
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  
  @media (--viewportMedium) {
    max-width: calc(100% - 72px);
  }
  
  @media (--viewportMLarge) {
    max-width: calc(100% - 134px);
  }

  & :global(.slick-next),
  & :global(.slick-prev) {
    width: 40px;
    height: auto;
    z-index: 1;
    
    @media (--viewportMLarge) {
      width: auto;
    }
  }

  & :global(.slick-next) {
    right: 0;
  }

  & :global(.slick-prev) {
    left: 0;
  }
}

.heroItem {
  position: relative;
  outline: none;
}

.heroItemSecond {

  & .heroItemTitle,
  & .heroItemSubTitle {
    position: absolute;
    top: 63px;
    right: 0;
    left: 0;
    font-size: 40px;
    line-height: 60px;
    max-width: 1356px;
    margin: 0 auto;
    text-align: left;
    padding: 0 50px;

    font-size: 24px;
    line-height: 1.2;

    @media (--viewportSmall) {
      font-size: 36px;
    }

    @media (--viewportMedium) {
      font-size: 40px;
      line-height: 60px;
      padding: 0 24px;
    }
  }
  
  & .heroItemSubTitle {
    top: 150px;
    text-shadow: 2px 0px 3px var(--matterColorLight);
    
    @media (--viewportMedium) {
      top: 129px;
    }
    
    @media (--viewportMLarge) {
      text-shadow: none;
    }

    & br {
      display: none;
      @media (--viewportMedium) {
        display: inline;
      }
    }
  }
}

.heroItemTitle {
  @apply --marketplaceHeroTitleFontStyles;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 50px;
  text-shadow: 2px 0px 3px var(--matterColorLight);
  
  @media (--viewportMedium) {
    top: 85px;
    padding: 0 24px;
  }
  
  @media (--viewportMLarge) {
    top: 130px;
    text-shadow: none;
  }
}

.heroItemImg {
  width: 100%;
  height: 440px;
  object-fit: cover;
  border-radius: var(--borderRadiusWrapper);

  @media (--viewportMedium) {
    height: 500px;
  }

  @media (--viewportMLarge) {
    height: 660px;
  }

  @media (--viewportLLarge) {
    height: 798px;
  }
}

