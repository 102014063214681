@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--customLightGreen);
  border-radius: var(--borderRadiusWrapper);
}

.topImages {
  position: absolute;
  top: -60px;
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;

  & img {
    object-fit: cover;
    border-radius: var(--borderRadiusWrapper);
  }

  @media (--viewportSmall) {
    display: flex;
  }

  @media (--viewportMLarge) {
    bottom: -75px;
  }

  @media (--viewportLLarge) {
    max-width: calc(100% - 214px);
  }
}

.topImagesLeft {
  width: 260px;
  height: 183px;

  @media (--viewportMLarge) {
    width: 280px;
    height: 223px;
  }

  @media (--viewportLLarge) {
    width: 370px;
    height: 293px;
  }
}

.topImagesRight {
  width: 249px;
  height: 180px;

  @media (--viewportMLarge) {
    width: 269px;
    height: 220px;
  }

  @media (--viewportLLarge) {
    width: 329px;
    height: 260px;
  }
}

.centralContent {
  width: 100%;
  max-width: 586px;
  margin: 0 auto;
  padding: 24px 24px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media (--viewportSmall) {
    padding: 165px 0;
  }
  
  @media (--viewportMLarge) {
    padding: 188px 0 95px;
  }

  @media (--viewportLLarge) {
    padding: 128px 0 95px;
  }
}

.centralContentTitle {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 4px;
  font-weight: var(--fontWeightBold);

  @media (--viewportSmall) {
    @apply --marketplaceH1CustomFontStyles;
  }
}

.centralContentSubTitle {
  font-size: 18px;
  line-height: 1.2;
  font-weight: var(--fontWeightBold);
  margin: 0 0 18px;
  color: var(--marketplaceColor);

  @media (--viewportSmall) {
    font-size: 24px;
    line-height: 29px;
  }
}

.centralContentList {
  list-style: disc;
  text-align: left;
  margin: 0 0 30px;
  padding: 0 0 0 40px;

  @media (--viewportSmall) {
    padding: 0 0 0 130px;
  }
}

.centralContentListItem {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 2px;
  font-weight: var(--fontWeightSemiBold);
}

.centralContentListBtn {
  @apply --marketplaceButtonStyles;
  padding: 0;
  max-width: 240px;
  margin: 0 auto;
}

.bottomImages {
  position: absolute;
  bottom: -60px;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;

  & img {
    object-fit: cover;
    border-radius: var(--borderRadiusWrapper);
  }

  @media (--viewportSmall) {
    display: flex;
  }

  @media (--viewportMLarge) {
    bottom: -70px;
  }

  @media (--viewportLLarge) {
    max-width: calc(100% - 744px);
  }
}

.bottomImagesLeft {
  width: 197px;
  height: 148px;

  @media (--viewportMLarge) {
    width: 237px;
    height: 188px;
  }
}

.bottomImagesRight {
  width: 240px;
  height: 182px;

  @media (--viewportMLarge) {
    width: 280px;
    height: 222px;
  }
}
