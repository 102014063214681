@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .notChecked,
  &:checked + label .required {
    stroke: var(--matterColorDark);
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }

  /* &:hover + .labelWithImage, */
  &:checked + .labelWithImage {
    background: var(--marketplaceColor);
  }

  
  /* &:hover + .labelWithImage .text, */
  &:checked + .labelWithImage .text {
    color: var(--matterColorLight);
  }

  
  /* &:hover + .labelWithImage path, */
  &:checked + .labelWithImage path {
    fill: var(--matterColorLight);
  }

  &:checked + .labelWithImage [class*="IconCategories_rootRent"] path {
    fill: none;
    stroke: var(--matterColorLight);
  }
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.notChecked {
  stroke: var(--matterColorAnti);
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--attentionColor);
  &:hover {
    stroke: pink;
  }
}

.labelWithImage {
  margin: 0 20px 0 0;
  padding: 11px 0 11px 20px;
  background: var(--matterColorLight);
  border-radius: var(--borderRadiusWrapper);
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid var(--customGrayBorder);
  
  @media (--viewportMedium) {
    justify-content: center;
    padding: 11px 0;
  }
  
  @media (--viewportMLarge) {
    width: 120px;
  }

  & .text {
    font-size: 14px;
    margin: 0;
    line-height: 1;
  }
}

.text {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
