@import '../../styles/propertySets.css';

.fieldRoot {
}

.labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.startDateLabel,
.endDateLabel {
  flex-basis: 50%;
  @apply --marketplaceH4FontStyles;
  font-weight: 600;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }

  @media (--viewportMedium) {
    padding-bottom: 1px;
  }
}

.labelSuccess {
  color: var(--successColor);
}

.inputBorders {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all 0.15s ease-out;
}

.input {
  flex-basis: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--attentionColor);

  transition: border-color var(--transitionStyle);
  transition: all 0.15s ease-out;

  &:hover,
  &:focus {
    border-color: var(--matterColor);
    outline: none;
  }
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}

.hover {
  border-color: var(--matterColorDark);
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  /* Mobile Safari couldn't handle width: calc(100vw - 48px); */
  width: calc(100vw - 48px);
  margin: 0 24px;

  /* Gutter between inputs (when calendar is not visible) */
  & .startDateLabel,
  & .endDateLabel {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #252525;
  }

  & .input {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
    transition: all 0.15s ease-out;
  }

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;

    /* Gutter between inputs (when calendar is not visible) */
    & .startDateLabel,
    & .endDateLabel {
      flex-basis: calc(50% - 12px);
    }

    & .input {
      flex-basis: calc(50% - 12px);
      transition: all 0.15s ease-out;
    }
  }
}
