.root {
  
  &:hover path {
    fill: #2F4E1E;
  }

  &:hover .arrow {
    fill: #fff;
  }
}
