@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 20px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.bookingDatesTitle {
  color: #252525;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.addGuestsBtn {
  background: #2F4E1E;
  border: 1px solid #E4E4E4;
  border-radius: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px 0px 13px;
  margin-bottom: 19px;
  cursor: pointer;
  height: 40px;
}

.addGuestsText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.addGuestsIcon {
  fill: transparent;
}

.bookingDatesDivider {
  width: 94%;
  height: 1px;
  background: #ADC9A0;
  margin: 0 auto 20px;

  @media (--viewportLarge) {
    width: 100%;
  }
}

.bookingDatesDetailsPrice {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  margin-bottom: 5px;
}

.bookingDatesDetailsPriceDesc {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #252525;
  margin: 0;
}

.addGuestsContainer {
  position: relative;
  padding: 0px 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.addGuestsMenu {
  position: absolute;
  top: 68%;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 20px 20px 18px;
}

.addGuestsMenuDivider {
  width: 100%;
  height: 1px;
  background: #D3E7C9;
  margin-top: 20px;
  margin-bottom: 20px;
}

.addGuestsPersonText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #4B4A4A;
}

.personCountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 28px;
}

.iconButton {
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border: 1.5px solid #DBEAD3;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  line-height: 23px;
}

.btnsContainer {
  display: flex;
  align-items: center;
  max-height: 40px;
}

.addGuestsCount {
  margin-left: 14px;
  margin-right: 14px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;


  color: #4B4A4A;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.btnsContainer button {
  font-size: 14px;
  line-height: 17px;
}

.btnsContainer button:first-child {
  width: 124px;
  margin-right: 70px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
}

.btnsContainer button:last-child {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-decoration-line: underline;

  color: #2F4E1E;
}


.bookingDatesDetails {
  padding: 0px 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.guestsError {
  margin: 0;
}
