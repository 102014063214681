@import '../../styles/propertySets.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding-top: 25px;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    padding-top: 0;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  justify-content: start;
  padding: 20px;

  @media (--viewportLarge) {
    justify-content: space-between;
    padding: 0;
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  border-radius: 20px;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
    height: auto;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  margin-bottom: 18px;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-right: 6px;

  margin-top: 0;
  margin-bottom: 0px;

  font-size: 24px;
  font-weight: 700;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;

    line-height: 28px;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: #464646;

  margin-top: 0;
  margin-bottom: 0px;

  font-size: 16px;
  line-height: 32px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.bookingWidget {
  @media (--viewportLarge) {
    background: #D3E7C9;
    border-radius: 20px;
    padding: 19px 29px 19px 29px;
  }
}

.userAbbreviatedNameWrapper {
  background: #2F4E1E;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  line-height: 46px;
  text-align: center;
  margin-right: 25px;

  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.userAbbreviatedName {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
}

.discountTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #464646;
  margin-right: 5px;
}

.discountsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 19px;
}

.discountsPopup {
  position: absolute;
  display: none;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  bottom: 25px;
  min-width: 136px;
  box-sizing: border-box;

  @media (--viewportLarge) {
    left: 50%;
  }
  @media (--viewportLLarge) {
    left: 83%;
  }
}

.infoIcon {
  margin-bottom: 11px;
  margin-left: 2px;
}

.discounts {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #464646;
}

.discountsInnerContainer:hover .discountsPopup {
  display: block;
}

.discountsList {
  margin: 0;
  padding: 9px 7px 9px 13px;
  position: relative;
}

.discountsListItem {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #464646;
}

.discountsListItem span {
  font-weight: 600;
}

.discountsList:after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  z-index: 4;
  bottom: -3.5px;
  left: 25px;
}

.priceContainerDesctop {
  max-height: 28px;
}

.from {
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
}
