.popupSize {
  padding: 0 10px 7px 10px;
}

div.searcDateFilter {
  width: 100%;
  margin: 0 0 14px 0;

  @media (--viewportMLarge) {
    width: auto;
    margin: 0 0 0 20px;
  }

  & > button {
    padding: 5px 10px;
    min-width: 170px;
    min-height: 36px;
    font-size: 12px;
    
    @media (--viewportLLarge) {
      min-width: 200px;
    }

    &:hover {
      background: var(--marketplaceColor);
      color: var(--matterColorLight);

      & svg path {
        stroke: var(--matterColorLight);
      }
    }

    &:before {
      display: none;
    }

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  & > button[class*="FilterPopup_labelSelected"] {
    background: var(--marketplaceColor);
    color: var(--matterColorLight);

    & svg path {
      stroke: var(--matterColorLight);
    }

    &:hover {
      background: var(--marketplaceColor);
      color: var(--matterColorLight);

      & svg path {
        stroke: var(--matterColorLight);
      }
    }
  }

  & > div {
    left: 0 !important;
  }
}
