@import '../../styles/propertySets.css';

.root {

}

.title {
  @apply --marketplaceH1CustomFontStyles;
  text-align: center;
  margin: 0 0 50px;
}

.suggestions {
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
  }
}

.suggestionsItem {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  
  @media (--viewportMedium) {
    height: 350px;
    width: calc((100%/3) - 26px);
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    height: 440px;
  }
}

.suggestionsItemSmall {
  position: relative;
  display: block;
  height: 100%;
 
  &:first-child {
    margin-bottom: 20px;
  }

  & .suggestionsImg {
    height: 300px;
    
    @media (--viewportMedium) {
      height: 165px;
    }

    @media (--viewportLarge) {
      height: 210px;
    }
  }
}

.suggestionsItem,
.suggestionsItemSmall {

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 77px;
    background: linear-gradient(180deg, #000000 -44%, rgba(0, 0, 0, 0) 100%);
    border-radius: var(--borderRadiusWrapper) var(--borderRadiusWrapper) 0 0;
  }
}

.suggestionsTitle {
  position: absolute;
  top: 15px;
  left: 28px;
  font-size: 24px;
  line-height: 29px;
  color: var(--matterColorLight);
  text-decoration: none;
  margin: 0;
}

.suggestionsImg {
  width: 100%;
  height: 300px;
  border-radius: var(--borderRadiusWrapper);
  object-fit: cover;

  @media (--viewportMedium) {
    height: 100%;
  }
}
