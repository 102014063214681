.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 96px 24px;
  
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 96px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 0 36px;
  }

  @media (--viewportMLarge) {
    /* padding: 0 75px 96px 50px; */
    padding: 0 0 96px 50px;
  }
}

.listingCard {
  flex-basis: 100%;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid var(--marketplaceColorLight);
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: #FCFCFC;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
