@import '../../styles/propertySets.css';

.root {
  position: fixed;
  right: 12px;
  top: calc(var(--topbarHeightDesktop) + 10px);

  display: flex;
  align-items: center;

  background: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusWrapper);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

  z-index: 1;
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin: 0;
  /* line-height: 17px; */
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  /* outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer; */

  display: none;
}

.optionsContainerOpen {
  height: auto;
  display: flex;
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.hasBullets {
  padding-left: 26px;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.optionBorder,
.optionBorderSelected {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBorder {
  width: 0;
  background-color: var(--matterColorDark);
}

/* left static border for selected element */
.optionBorderSelected {
  width: 8px;
  background-color: var(--matterColorDark);
}

.optionBullet,
.optionBulletSelected {
  position: absolute;
  left: -5px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--marketplaceColor);
  transition: opacity var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBullet {
  opacity: 0;
}

/* left static border for selected element */
.optionBulletSelected {
  opacity: 1;
}

.option {
  display: flex;
  align-items: center;

  min-width: 96px;
  margin: 7px 10px;
  padding: 4px 7px;
  background: var(--matterColorLight);
  border-radius: var(--borderRadiusWrapper);
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 14px;
  text-align: left;

  cursor: pointer;

  & svg {
    width: 11px;
    height: 14px;
    margin-right: 6px;
  }

  &:hover,
  &:focus {
    background: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
}

.optionSelected {
  composes: option;
  /* color: var(--matterColorDark); */
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.clearButton {
  font-size: 14px;
  line-height: 17px;
  font-weight: var(--fontWeightRegular);
  color: var(--customBlue);

  /* Layout */
  display: inline;
  margin: 0 11px 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  text-decoration: underline;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}
