.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.featuresHolder {
  width: 100%;
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }

  & legend {
    padding: 0 0 15px;
    font-size: 16px;
    line-height: 19.5px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 320px;
    /* margin-top: 100px; */
  }
}