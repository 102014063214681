@import '../../styles/propertySets.css';

.root,
.rooLink {
  /* Layout */
  display: flex;
  flex-direction: column;
  /* flex-direction: column; */

  position: relative;

  @media (--viewportMLarge) {
    flex-direction: row;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.rooLink {
  width: 100%;
}

.sliderWrapper {
  /* Layout */
  display: block;
  overflow: hidden;
  position: relative;
  transition: var(--transitionStyleButton);
  flex-shrink: 0;
  border-radius: var(--borderRadiusWrapper);
  max-width: 339px;
  width: 100%;
  margin: 0 auto;
  
  @media screen and (min-width: 480px) {
    max-width: 414px;
  }
  
  @media (--viewportMedium) {
    max-width: 565px;
  }

  @media (--viewportLarge) {
    max-width: 526px;
  }
  
  @media (--viewportMLarge) {
    width: 380px;
    max-width: 475px;
  }
  
  & :global(.slick-slide) {
    & > div {
      height: 214px;
    }
  }

  & :global(.slick-prev),
  & :global(.slick-next) {
    width: 32px;
    height: 27px;
    z-index: 1;
  }

  & :global(.slick-prev) {
    left: 50%;
    transform: translateX(-50px);
    bottom: -60px;
    top: auto;

    @media (--viewportSmall) {
      bottom: auto;
      top: 45%;
      left: 0;
      transform: translateX(0);
    }
  }

  & :global(.slick-next) {
    right: 50%;
    bottom: -60px;
    top: auto;
    transform: translateX(50px);

    @media (--viewportSmall) {
      bottom: auto;
      top: 45%;
      right: 0;
      transform: translateX(0);
    }
  }
}

.sliderImage {
  position: relative;
  height: 214px;
  
  @media (--viewportMLarge) {
    width: 380px;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadiusWrapper);
}

.info {
  /* Layout */
  /* display: flex;
  flex-direction: row; */
  padding: 18px 0 0 0;
  position: relative;
  width: 100%;
  
  @media (--viewportMLarge) {
    padding: 18px 0 0 18px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1366px) {
    padding: 10px 0 0 18px;
  }
  
  @media (--viewportLLarge) {
    padding: 18px 0 0 54px;
  }
}

.bottomInfo {
  
  @media (--viewportMLarge) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.bottomInfoItem {

}

.priceHolder {
  
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  @media (--viewportMLarge) {
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.price {
  /* Layout */
  flex-shrink: 0;
  margin: 0;
  line-height: 20px;
  
  @media (--viewportMLarge) {
    margin: 0 0 20px;
  }
  
  @media (--viewportLLarge) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }
}

.priceValue {
  /* Font */
  font-size: 28px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  margin: 0 8px 0 0;
}

.perUnit {
  position: relative;
  top: 2px;
  /* Font */
  color: var(--customGray);

}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.titleHolder {
  display: flex;
  align-items: center;
  margin: 0 0 9px;
  
  @media (--viewportLarge) {
    padding-right: 50px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1366px) {
    display: block;
    padding-right: 0;
  }
}

.title {

  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  margin: 0;
  font-size: 22px;
  line-height: 27px;
  font-weight: var(--fontWeightBold);
  color: var(--customBlack);
}

.category {
  min-height: 30px;
  text-transform: uppercase;
  color: var(--matterColorLight);
  font-size: 14px;
  line-height: 30px;
  background: var(--marketplaceColor);
  border-radius: 22px;
  padding: 0 10px 0 12px;
  margin-right: 12px;
  font-weight: var(--fontWeightBold);

  @media screen and (min-width: 1280px) and (max-width: 1366px) {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.categoryRent {
  background: var(--categoryRent);
}

.categoryActivities {
  background: var(--categoryActivities);
}

.description {
  margin: 0 0 30px;
  line-height: 20px;
  color: var(--customGray);
  min-height: 20px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.discountHolder {
  display: flex;
  position: relative;
  top: -5px;
}

.ratingHolder {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0 0 7px;  
}

.ratingStar {
  margin: 0 4px 3px 0;
}

.ratingTotal {
  margin-left: 6px;
  color: var(--customBlue);
  text-decoration: underline;
  font-weight: var(--fontWeightRegular);
}

.additionalInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.additionalInfoItem {
  width: 100%;
  min-height: 22px;
  margin-right: 28px;
  text-transform: capitalize;
  color: var(--customBlack);
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 12px;
}

.additionalInfoLabel {
  color: var(--customGray);
  margin-right: 6px;
}

.amenities {
  margin-bottom: 18px;

  & svg {
    margin-right: 20px;
  }
}

.bookBtn {
  @apply --marketplaceButtonStyles;
  width: 100%;
  min-height: 60px;
  padding: 0;
  margin-top: 20px;
  
  @media (--viewportLarge) {
    width: 170px;
    margin-top: 0;
  }
}

.addToFavorites {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  cursor: pointer;

  @media (--viewportSmall) {
    right: 0;
    top: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 768px) {
    right: 10px;
    top: 10px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1024px) {
    right: 20px;
    top: 10px;
  }

  background: var(--matterColorLight);
  border-radius: 10px;
}
