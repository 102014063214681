@import '../../styles/propertySets.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  background: #FCFCFC;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: #FCFCFC;
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 70px 0 82px 24px;
    flex-direction: column;
    overflow-x: auto;
    /* background-color: var(--matterColorBright); */
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 70px 0 82px 24px;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & [class*="TabNav_link"] {
    margin: 0;
    background-image: none;
    font-weight: var(--fontWeightMedium);
    color: var(--customGray);

    @media (--viewportLarge) {
      border-radius: 20px 0px 0px 20px;
      padding: 17px 4px 17px 23px;
      font-size: 24px;
      line-height: 26px;
      white-space: break-spaces;
    }
  }

  & [class*="TabNav_selectedLink"] {
    color: var(--matterColor);
    font-weight: var(--fontWeightBold);

    @media (--viewportLarge) {
      background: var(--marketplaceColorLight);
    }
  }
}

.panel {
  flex-grow: 1;
  border: 1px solid var(--customGrayBorder);
  border-radius: var(--borderRadiusWrapper);
  max-width: calc(100% - 20px);
  padding: 14px;
  margin: 24px auto;

  @media (--viewportSmall) {
    max-width: calc(100% - 48px);
    padding: 24px;
  }

  @media (--viewportLarge) {
    max-width: 1110px;
    margin: 10px 24px 50px 0;
    padding: 67px 36px 50px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 67px 24px 26px 83px;
  }

  & h1,
  & form {
    max-width: 756px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
