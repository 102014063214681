@import '../../styles/propertySets.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .priceLabel,
    & .caret {
      cursor: pointer;

      /* Same as active */
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--matterColorLight);
      box-shadow: var(--boxShadowPopup);
    }
  }
}

.priceLabel {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  /* Font */
  font-size: 12px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--customBlack);
  

  background-color: var(--matterColorLight);

  /* Borders */

  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);

  /* Dimensions */
  padding: 4px 11px;
  margin-top: 0;
  margin-bottom: 7px;
  transition: var(--transitionStyleButton);

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 7px;
  }
}

.priceLabelActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  box-shadow: var(--boxShadowPopup);
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  /* bottom: -3px; */
  top: 25px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  transition: var(--transitionStyleButton);

  /* Caret should have same bg-color and border as label */
  background-color: var(--matterColorLight);
  border-right-style: solid;
  border-right-color: var(--matterColorNegative);
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--matterColorNegative);
  border-bottom-width: 1px;
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}
