@import '../../styles/propertySets.css';

.root {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  background-color: #fff;
  max-height: 100vh;
  overflow-y: auto;
}

.imagesColumn {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 83.3333%;
  margin: 0 auto;
  padding: 40px 0 24px;

  @media (--viewportMedium) {
    width: 66.3333%;
  }

  @media (--viewportLarge) {
    width: 50%;
  }
}

.imageWrapper {
  width: 100%;
  height: auto;
  display: block;
  padding: 4px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: #000;
    opacity: 0;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 4px;
  }

  &:hover::before {
    opacity: 0.2;
  }

  &:nth-child(2n) {
    width: 50%;
  }

  &:nth-child(3n) {
    width: 50%;
  }

  &:nth-child(4n) {
    width: 100%;
  }
}

.evenImageWrapper {
  &:last-child {
    width: 100%;
  }
}
