.root {
}

.select {
  color: var(--customGray);
  border-color: var(--customGrayBorder);
  padding-right: 20px;

  & > option {
    color: var(--matterColorDark);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColorDark);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
