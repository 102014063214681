@import '../../styles/propertySets.css';

.root {

}

.wrapper {

}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.servicesHolder {
  margin: 0 0 30px;
  
  @media (--viewportSmall) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.services {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 14px;
}

.servicesTwoColums {
  @media (--viewportSmall) {
    width: calc(50% - 20px);
  }
}

.servicesTitle {
  
}

.servicesAction {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  align-self: center;
  white-space: nowrap;

  & label {
    margin: 0;
  }
}

.spanDelete {

}

.spanClose {
  margin-left: 5px;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  color: var(--failColor);
}

.spanAdd {
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: var(--fontWeightBold);

  & svg {
    width: 60px;
    height: 60px;
  }
}

.blockAddServiceIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconBlockAdd {
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.wrapperForm {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.buttonAddService {
  /* margin-left: 20px; */
  cursor: pointer;
  width: max-content;
  font-size: 16px;
  line-height: 21px;
  font-weight: var(--fontWeightRegular);
}

.wrapperInputBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.inputComponentText {
  margin-right: 45px;
  width: 150px;
}

.inputComponentPrice {
  width: 100px;
}

.inputNewService {
  width: 100%;
  max-width: 676px;
  border-radius: var(--borderRadiusWrapper);
  min-height: 60px;
  height: 60px;
}

.error {
  color: var(--failColor);
}

.optionalLabel {
  margin-left: 5px;
}
  